<template>
    <default-app-template>
        <basic-headline>Export</basic-headline>
        <p class="font-bold my-5 text-center text-gray-500">
            This feature will be available soon..
        </p>
    </default-app-template>
</template>
<script>
export default {
    name: 'Export',
}
</script>
